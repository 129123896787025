.gui {
  z-index: 1001;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;

  * {
    pointer-events: all;
  }

  &__element {
    position: absolute;

    &--top-right {
      top: 24px;
      right: 24px;
    }

    &--top-left {
      top: 24px;
      left: 24px;
    }

    &--bottom-right {
      bottom: 24px;
      right: 24px;
    }

    &--bottom-left {
      bottom: 24px;
      left: 24px;
    }

    &--bottom-center {
      bottom: 24px;
      left: 50%;
      transform: translateX(-50%);
    }

    &--top-center {
      top: 24px;
      left: 50%;
      transform: translateX(-50%);
    }

    @include for-giant-desktop-up {
      &--top-right {
        top: rs(24);
        right: rs(24);
      }

      &--top-left {
        top: rs(24);
        left: rs(24);
      }

      &--bottom-right {
        bottom: rs(24);
        right: rs(24);
      }

      &--bottom-left {
        bottom: rs(24);
        left: rs(24);
      }

      &--bottom-center {
        bottom: rs(24);
        left: 50%;
        transform: translateX(-50%);
      }

      &--top-center {
        top: rs(24);
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &__logo {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1002;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--primary-color);
    width: 7.2rem;
    height: 7.2rem;
    opacity: 1;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: 0 0;
    }
  }

  &__title {
    position: absolute;
    padding: 2.4rem !important;
    bottom: 0;
    right: 2rem;
    left: 2rem;
    background-color: $color-white;
    -webkit-box-shadow: 1.2rem -1.2rem 0px 0px rgba($color-black, 0.2);
    box-shadow: 1.2rem -1.2rem 0px 0px rgba($color-black, 0.2);
    pointer-events: none;

    * {
      font-weight: normal;
      pointer-events: all;
    }

    h2 {
      font-size: 1.3rem;
      text-transform: uppercase;
    }

    h1 {
      font-size: 2.8rem;
      line-height: 3.8rem;
      margin-top: 0.8rem;
      max-width: 80%;
    }

    @include for-tablet-landscape-up {
      h1 {
        font-size: 3.2rem;
        line-height: 4.2rem;
      }

      max-width: 42rem;
      bottom: 2rem;
    }
  }

  &__webvr {
    background-color: var(--accent-color);
    display: none;
    cursor: pointer;
    top: 2rem;
    transition: opacity 0.1s linear;

    a {
      padding: 1.6rem 2.4rem;
      color: $color-white;
      text-decoration: none;
      display: block;
      font-weight: bold;
    }

    &:hover {
      opacity: 0.5;
    }

    @include for-tablet-landscape-up {
      display: block;
    }
  }

  @include for-giant-desktop-up {
    &__logo {
      width: rs(72);
      height: rs(72);
    }

    &__title {
      padding: rs(24) !important;
      right: rs(20);
      left: rs(20);
      -webkit-box-shadow: rs(12) rs(-12) 0px 0px rgba($color-black, 0.2);
      box-shadow: rs(12) rs(-12) 0px 0px rgba($color-black, 0.2);

      h2 {
        font-size: rs(13);
      }

      h1 {
        font-size: rs(32);
        line-height: rs(42);
        margin-top: rs(8);
      }

      max-width: rs(420);
      bottom: rs(20);
    }

    &__webvr {
      top: rs(20);

      a {
        padding: rs(16) rs(24);
      }
    }
  }
}

.side-menu {
  &__button {
    top: 0;
    right: 0;
    position: absolute;
    padding: 2.8rem 2.3rem;
    background-color: $color-white;
    display: flex;
    align-items: center;
    grid-gap: 1.4rem;
    text-decoration: none;
    transition: all 0.1s linear;

    span {
      display: none;
      font-weight: bold;
      font-size: 1.7rem;
      color: var(--accent-color);
    }

    &-icon {
      position: relative;
    }

    &:hover {
      background-color: #ecf2f8;
    }

    @include for-tablet-landscape-up {
      top: 2rem;
      right: 2rem;
      padding: 1.4rem 2.4rem;

      span {
        display: block;
      }

      &-icon {
        height: 1.2rem;
      }
    }
  }

  @include for-giant-desktop-up {
    &__button {
      span {
        font-size: rs(17);
      }

      top: rs(20);
      right: rs(20);
      padding: rs(14) rs(24);
      grid-gap: rs(14);

      &-icon {
        height: rs(12);
      }
    }
  }
}
