
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          


















































.languages {
  display: none;
  align-items: center;
  justify-content: center;
  grid-gap: 6.4rem;
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: fixed;
  bottom: 3.2rem;

  * {
    color: $color-white;
  }

  a {
    text-decoration: none;
    transition: all 0.1s linear;
    font-size: 1.6rem;
    text-transform: uppercase;

    &:hover {
      opacity: 0.5;
    }
  }

  @include for-tablet-landscape-up {
    display: flex;
  }

  @include for-giant-desktop-up {
    grid-gap: rs(64);
    bottom: rs(32);

    a {
      font-size: rs(16);
    }
  }
}
