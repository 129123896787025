//// Color Variables

$color-text: #000000;
$color-text-inverse: #ffffff;

$color-black: #000000;
$color-white: #ffffff;
$color-grey: #9f9f9f;

//// Text Variables

$font-family-title: "Antwerpen Regular", sans-serif;
$font-family-text: "Sun Antwerpen", sans-serif;
$font-family-small: "Antwerpen Small", sans-serif;

$title-size-mobile: 2.9rem;
$title-size-tablet-portrait: 3.6rem;
$title-size-tablet-landscape: 4.2rem;
$title-size-desktop: 4.8rem;
$title-size-4k: rs(48);

$subtitle-size-mobile: 2rem;
$subtitle-size-tablet-portrait: 2rem;
$subtitle-size-tablet-landscape: 2.2rem;
$subtitle-size-desktop: 2.2rem;
$subtitle-size-4k: rs(22);

$text-size-mobile: 1.6rem;
$text-size-tablet-portrait: 1.6rem;
$text-size-tablet-landscape: 1.8rem;
$text-size-desktop: 1.8rem;
$text-size-4k: rs(18);

//// Animation Variables

$basic-transition-100: 100ms cubic-bezier(0.86, 0, 0.07, 1);
$basic-transition-200: 200ms cubic-bezier(0.86, 0, 0.07, 1);
$basic-transition-300: 300ms cubic-bezier(0.86, 0, 0.07, 1);
$basic-transition-500: 500ms cubic-bezier(0.86, 0, 0.07, 1);
$basic-transition-1000: 1s cubic-bezier(0.86, 0, 0.07, 1);

$bounce-transition-300: 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
$bounce-transition-500: 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);


////// Swiper config
.swiper {
    --swiper-pagination-color: #ffb300;
    --swiper-pagination-bullet-size: 1rem;
    --swiper-pagination-bullet-inactive-color: white;
    --swiper-pagination-bullet-inactive-opacity: 1;
  
    --swiper-navigation-color: white;
    --swiper-navigation-size: 3rem;
  }
  
  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 1.4rem !important;
  }
  
