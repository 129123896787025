
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          






























p {
  font-size: 1.6rem;
  line-height: 2rem;
  margin-top: 1rem;

  @include for-giant-desktop-up {
    font-size: rs(16);
    line-height: rs(20);
    margin-top: rs(10);
  }
}

.read-more {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  cursor: pointer;

  * {
    transition: all 0.1s linear;
  }

  a {
    font-size: 1.6rem;
    font-weight: bold;
    text-decoration: underline;
  }

  img {
    max-height: 2.2rem;
  }

  &:hover {
    a {
      text-decoration: none;
    }

    img {
      opacity: 0.5;
      margin-left: 0.6rem;
    }
  }

  @include for-giant-desktop-up {
    margin-top: rs(10);

    a {
      font-size: rs(16);
    }

    img {
      width: rs(22);
      max-height: rs(22);
    }

    &:hover {
      img {
        margin-left: rs(6);
      }
    }
  }
}
