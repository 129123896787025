
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          





























































.title-modal {
  &__container {
    position: absolute;
    background: rgba(#424242, 0.9);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1003;
    opacity: 0;
    visibility: hidden;
    transition: opacity $basic-transition-500 0.1s;

    &_open {
      pointer-events: all;
      opacity: 1;
      visibility: visible;

      .title-modal__outer {
        pointer-events: all;
      }

      .close {
        opacity: 1 !important;
      }
    }
  }

  &__inner {
    background: $color-white;
    padding: 7.6rem 2.4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    position: absolute;
    overflow-y: auto;
    z-index: 1003;

    * {
      font-weight: normal;
    }

    h2 {
      font-size: 1.3rem;
      text-transform: uppercase;
    }

    h1 {
      font-size: 4rem;
      line-height: 4.2rem;
      margin: 1.2rem 0;
      color: var(--primary-color);
    }

    p {
      margin-top: 1.6rem;
      font-size: 1.6rem;
      line-height: 2rem;
    }

    .close {
      position: absolute;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.4rem;
      height: 2.4rem;
      background: transparent;
      opacity: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.1s linear;

        // #6F6F6F
        filter: invert(45%) sepia(0%) saturate(1%) hue-rotate(138deg)
          brightness(95%) contrast(89%);
      }

      &:hover img {
        opacity: 0.5;
      }
    }

    @include for-tablet-landscape-up {
      padding: 7.6rem 7.6rem 7.6rem 5rem;
      width: 60%;
      height: auto;
      max-height: 60%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__outer {
    pointer-events: all;
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  @include for-giant-desktop-up {
    &__inner {
      h2 {
        font-size: rs(13);
      }

      h1 {
        font-size: rs(40);
        line-height: rs(42);
        margin: rs(12) 0;
      }

      p {
        margin-top: rs(16);
        font-size: rs(16);
        line-height: rs(20);
      }

      .close {
        width: rs(24);
        height: rs(24);
      }

      padding: rs(76) rs(76) rs(76) rs(50);
    }
  }
}
