// https://medium.freecodecamp.com/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862#.adpufyefv

$breakpointMap: (
    "for-tablet-portrait-up": "600px",
    "for-tablet-landscape-up": "900px",
    "for-desktop-up": "1200px",
    "for-medium-desktop-up": "1600px",
    "for-big-desktop-up": "1800px",
    "for-giant-desktop-up": "2000px",
);

@mixin for-tablet-portrait-up {
    @media (min-width: map-get($breakpointMap, "for-tablet-portrait-up")) {
        @content;
    }
}

@mixin for-tablet-landscape-up {
    @media (min-width: map-get($breakpointMap, "for-tablet-landscape-up")) {
        @content;
    }
}

@mixin for-desktop-up {
    @media (min-width: map-get($breakpointMap, "for-desktop-up")) {
        @content;
    }
}

@mixin for-medium-desktop-up {
    @media (min-width: map-get($breakpointMap, "for-medium-desktop-up")) {
        @content;
    }
}

@mixin for-big-desktop-up {
    @media (min-width: map-get($breakpointMap, "for-big-desktop-up")) {
        @content;
    }
}

@mixin for-giant-desktop-up {
    @media (min-width: map-get($breakpointMap, "for-giant-desktop-up")) {
        @content;
    }
}

@mixin mediaquery($key) {
    @if (map-has-key($breakpointMap, $key)) {
        @media (min-width: map-get($breakpointMap, $key)) {
            @content;
        }
    } @else {
        @error "Breakpoint '#{$key}' doesn't exist";
    }
}
