
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          


















































































.intro,
.tutorial {
  z-index: 10000;

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1001;
    background-color: transparent;
    transition: opacity $basic-transition-200;
    background-color: rgba(#424242, 0.45);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  &__modal {
    background-color: $color-white;
    width: calc(100% - 2 * 2.4rem);
    margin: 0 auto;
    position: absolute;
    bottom: 2.4rem;
    display: flex;
    justify-content: space-between;
    grid-gap: 1.6rem;
    flex-direction: column;
    padding: 2.4rem;

    h2 {
      color: var(--primary-color);
      font-weight: normal;
      font-size: 4rem;
      line-height: 4.2rem;
    }

    p,
    a {
      font-size: 1.6rem;
      line-height: 2rem;
    }

    a {
      cursor: pointer;
      padding: 1.6rem 7.2rem;
      background-color: var(--accent-color);
      color: $color-white;
      font-weight: bold;
      transition: all 0.1s linear;

      &:hover {
        background-color: var(--accent-color-darker);
      }
    }

    @include for-tablet-landscape-up {
      align-items: center;
      padding: 4.8rem;
      grid-gap: 2.4rem;
      top: 50%;
      left: 50%;
      right: unset;
      bottom: unset;
      transform: translate(-50%, -50%);
      max-width: 50%;
      max-width: 55rem;
    }
  }

  @include for-giant-desktop-up {
    &__overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1001;
      background-color: transparent;
      transition: opacity $basic-transition-200;
      background-color: rgba(#424242, 0.45);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
    }

    &__modal {
      width: calc(100% - 2 * #{rs(24)});
      bottom: rs(24);
      padding: rs(48);
      grid-gap: rs(24);
      max-width: rs(550);

      h2 {
        font-size: rs(40);
        line-height: rs(42);
      }

      p,
      a {
        font-size: rs(16);
        line-height: rs(20);
      }

      a {
        padding: rs(16) rs(72);
      }
    }
  }
}

.tutorial {
  &__modal {
    grid-gap: 3.6rem;
  }

  h2 {
    font-size: 3.2rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;

    &--desktop div {
      display: flex;
      align-items: center;
      grid-gap: 2.4rem;
    }

    img {
      width: 4.4rem;
      height: 4.4rem;
    }
  }

  p {
    font-weight: bold;
    font-size: 1.7rem;
  }

  @include for-giant-desktop-up {
    &__modal {
      grid-gap: rs(36);
    }

    h2 {
      font-size: rs(32);
    }

    &__content {
      grid-gap: rs(10);

      &--desktop div {
        grid-gap: rs(24);
      }

      img {
        width: rs(44);
        height: rs(44);
      }
    }

    p {
      font-size: rs(17);
    }
  }
}
