
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          
@import "./scss/lib/swiper-bundle.min.css";

html,
body,
#app {
  height: 100%;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  font-family: $font-family-text;
  color: $color-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin: 0;
  padding: 0;

  * {
    font-family: $font-family-text;
  }

  @include for-giant-desktop-up {
    font-size: rs(16);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-title;
  font-weight: 900;
}

p,
blockquote,
a,
span {
  font-family: $font-family-text;
  font-weight: normal;
}

h1,
h1 span {
  font-weight: bold;
  font-size: $title-size-mobile;
  line-height: $title-size-mobile;

  @include for-tablet-portrait-up {
    font-size: $title-size-tablet-portrait;
    line-height: $title-size-tablet-portrait;
  }

  @include for-tablet-landscape-up {
    font-size: $title-size-tablet-landscape;
    line-height: $title-size-tablet-landscape;
  }

  @include for-desktop-up {
    font-size: $title-size-desktop;
    line-height: $title-size-desktop;
  }

  @include for-giant-desktop-up {
    font-size: $title-size-4k;
    line-height: $title-size-4k;
  }
}

h2,
h2 span {
  font-weight: bold;
  font-size: $subtitle-size-mobile;
  line-height: $subtitle-size-mobile;

  @include for-tablet-portrait-up {
    font-size: $subtitle-size-tablet-portrait;
    line-height: $subtitle-size-tablet-portrait;
  }

  @include for-tablet-landscape-up {
    font-size: $subtitle-size-tablet-landscape;
    line-height: $subtitle-size-tablet-landscape;
  }

  @include for-desktop-up {
    font-size: $subtitle-size-desktop;
    line-height: $subtitle-size-desktop;
  }

  @include for-giant-desktop-up {
    font-size: $subtitle-size-4k;
    line-height: $subtitle-size-4k;
  }
}

p,
p span,
a,
a span {
  font-weight: normal;
  font-size: $text-size-mobile;
  line-height: calc(#{$text-size-mobile} + 0.5rem);

  @include for-tablet-portrait-up {
    font-size: $text-size-tablet-portrait;
    line-height: calc(#{$text-size-tablet-portrait} + 0.5rem);
  }

  @include for-tablet-landscape-up {
    font-size: $text-size-tablet-landscape;
    line-height: calc(#{$text-size-tablet-landscape} + 0.5rem);
  }

  @include for-desktop-up {
    font-size: $text-size-desktop;
    line-height: calc(#{$text-size-desktop} + 0.5rem);
  }

  @include for-giant-desktop-up {
    font-size: $text-size-4k;
    line-height: calc(#{$text-size-4k} + 1rem);
  }
}

.small-caps {
  font-family: $font-family-small;
}

a {
  color: var(--accent-color);
}

#krpanoObject {
  z-index: 1000;
}

body > #krpanoObject {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

::-webkit {
  &-scrollbar {
    width: 4px;

    &-track {
      background: darken($color-white, 5%);
    }

    &-thumb {
      background: var(--primary-color);

      &:hover {
        // Darken
        background: hsl(var(--primary-color), 100%, calc(var(50%) - 10%));
      }
    }

    @include for-giant-desktop-up {
      width: rs(4);
    }
  }
}

/* Tinybox styling */
.tinybox {
  z-index: 8000 !important;
  background: rgba(66, 66, 66, 1) !important;

  &__content {
    &__image {
      border-radius: 4px !important;
    }

    &__control--close {
      top: 16px !important;
    }
  }

  @include for-giant-desktop-up {
    &__content {
      &__image {
        border-radius: rs(4) !important;
      }

      &__control--close {
        top: rs(16) !important;
      }
    }
  }
}

.gallery {
  .flicking-camera {
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
  }
}

.modal {
  .flicking-camera {
    display: inline-flex !important;
  }
}

.invisible {
  opacity: 0 !important;
  pointer-events: none !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  z-index: -1 !important;
}

/* Inner image zoom styling */
.iiz__hint {
  display: none !important;
}

.image-modal__inner {
  pointer-events: none;

  * {
    pointer-events: all;
  }

  div,
  .iiz__img {
    height: 100% !important;
    margin: 0 auto;
  }
}
