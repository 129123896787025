
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          
























































































































.audio {
  width: 100%;
  background: #dbe2e9;
  padding: 0.3rem 1.2rem 0.3rem 0.3rem;
  display: flex;
  align-items: center;
  grid-gap: 2rem;
  min-height: 5rem;

  @include for-tablet-landscape-up {
    padding: 0.3rem;
  }

  &__icon {
    height: 4.4rem;
    width: 4.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      width: 75%;
      height: 75%;
    }
  }

  &__seeker {
    cursor: pointer;
    flex: 1;
    height: 0.5rem;
    background: white;
    position: relative;
    overflow: hidden;

    .seeker {
      pointer-events: none;
      background: var(--accent-color);
      height: 100%;
      left: 0;
      width: 0;
      position: relative;
    }
  }

  &__action {
    background: var(--accent-color);
    margin-right: 0.6rem;

    @include for-tablet-landscape-up {
      margin-right: 0;
    }
  }

  p {
    margin-left: 0.6rem;

    @include for-tablet-landscape-up {
      margin-left: 0;
    }
  }

  &__mute {
    display: none;

    @include for-tablet-landscape-up {
      display: flex;
    }
  }

  audio {
    display: none;
  }

  @include for-giant-desktop-up {
    padding: rs(3);
    grid-gap: rs(20);
    min-height: rs(50);

    &__icon {
      height: rs(44);
      width: rs(44);
    }

    &__seeker {
      height: rs(5);
    }
  }
}
